<template>
  <div class="login w-100_ h-100_ p-r">
    <!-- <div class="login-header d-f ai-c jc-sb">
   
      <el-button type="primary" size="small"> 登 录</el-button>
    </div> -->
    <img
      style="z-index: -1"
      class="w-100_ h-100_ p-a"
      src="@/assets/login-content.png"
      alt=""
    />
    <div class="login-content-box w-100_ p-r">
      <div
        style="width: 1200px; margin: 0 auto; right: 0"
        class="p-a t-0 l-0 w-100_ h-100_ d-f jc-sb r-0 b-0"
      >
        <div class="login-content-title ta-l">
          <div class="d-f ai-c " style="margin-bottom:40px">
            <img style="width: 40px" src="@/assets/logo.png" alt="" />
            <span class="" style="color: white; font-size: 18px; margin-left: 8px;"
              >洛克侠</span
            >
          </div>
          <p>智慧宿管后台管理系统 2.0</p>
          <!-- <span>北京洛克家智能科技有限公司</span> -->
        </div>
        <div class="login-content-enter-box ta-l">
          <div class="login-content-enter-title"><span>LUOKER</span>洛克家</div>
          <div class="login-content-welcome">欢迎您，请登录！</div>
          <el-input v-model="username" placeholder="用户名/手机号"></el-input>
          <el-input
            v-model="password"
            type="password"
            placeholder="请输入密码"
          ></el-input>
          <div class="d-f jc-sb c-p">
            <el-input v-model="code" style="width: 50%"> </el-input>
            <img
              class="c-p"
              @click="getCodeImage"
              style="height: 32px"
              :src="imgSrc"
              alt=""
            />
          </div>
          <div class="d-f jc-sb ai-c">
            <el-checkbox v-model="checked">记住密码</el-checkbox>
            <!-- <span class="c-p c-py fs-12" @click="forgotPass">忘记密码?</span> -->
          </div>
          <el-button
            @click="loginSubmit"
            type="primary"
            class="w-100_ login-submit-btn"
            >登 录</el-button
          >
        </div>
      </div>
    </div>
    <!-- <div style="background: rgb(235, 236, 237)">
      <div class="company-message d-f jc-sb">
        <div class="ta-l">
          <div class="fw-b">北京洛克家智能科技有限公司</div>
          <div class="c-9 fs-12" style="color: #9a9a9a">
            Beijing Lokjia Intelligent Technology Co., Ltd
          </div>
          <div style="margin-top: 12px" class="fs-12 c-gray">
            地址: 北京市中关村东升国际科技园
          </div>
          <div class="fs-12 c-gray">邮编: 10000</div>
        </div>
        <div>
          <img src="@/assets/qrcode.png" alt="" />
          <div
            style="color: #7b7b7b; font-weight: 400; font-size: 14px"
            class="fs-14 ta-c"
          >
            扫码下载app
          </div>
        </div>
      </div>
    </div> -->
    <!-- <div class="footer c-9 fs-12">洛克家1.1.0 All rights reserved.</div> -->
    <!-- <el-dialog :visible.sync="showForgotPass" width="480px">
      <div slot="title" class="d-f">
        <img src="@/assets/warn.png" style="margin-right: 16px" alt="" />
        <strong>重新发送新密码</strong>
      </div>
      <div class="ta-l" style="padding-left: 43px">
        新密码已发送到尾号{{
          phoneEnd
        }}手机上，登录后可在<b>系统设置</b>中更换手机号码
      </div>

      <div style="margin-top: 16px">
        收不到短信？前往<span
          class="c-py c-p"
          @click="
            showAnswer = true;
            showForgotPass = false;
          "
          >回答问题找回密码</span
        >
      </div>
    </el-dialog>
    <el-dialog
      :visible.sync="showAnswer"
      width="360px"
      custom-class="answer-box"
    >
      <strong slot="title">回答问题找回密码</strong>
      <div
        class="fs-12 ta-l"
        style="color: rabg(0, 0, 0, 0.5); margin-bottom: 17px"
      >
        请认真填写以下资料，为防止他人冒充，请填写真实数据通过验证找回密码
      </div>
      <el-input
        v-model="answerForm.phoneNumber"
        placeholder="请填写原手机号"
      ></el-input>
      <el-input
        v-model="answerForm.housemaster"
        placeholder="请填写2-3名宿管姓名"
      ></el-input>
      <el-input
        v-model="answerForm.historyPassword"
        placeholder="请填写历史密码"
      ></el-input>
      <div style="margin-top: 24px" class="d-f ai-c btn-box">
        <el-button size="small" @click="showAnswer = false">取 消</el-button>
        <el-button size="small" type="primary" @click="sure">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog
      :visible.sync="showResult"
      custom-class="result-box"
      width="430px"
    >
      <img v-if="!newPass" src="@/assets/fail.png" alt="" class="m-a" />
      <img v-else src="@/assets/success.png" alt="" class="m-a" />
      <div class="result-title">{{ newPass ? "验证成功" : "验证失败" }}</div>
      <div class="result-detail">
        {{ newPass ? "您的新密码为: " + newPass : "信息填写失败" }}
      </div>
      <div class="btn-box">
        <el-button type="primary" v-if="newPass" @click="showResult = false"
          >前往登录</el-button
        >
        <el-button v-if="!newPass" @click="showResult = false"
          >返回页面</el-button
        >
        <el-button
          type="primary"
          v-if="!newPass"
          @click="
            showResult = false;
            showAnswer = true;
          "
          >重新验证</el-button
        >
      </div>
    </el-dialog> -->
    <div class="p-a b-0 t-a w-100_" style="bottom:0;text-align:center;font-size: 12px;">
      <a href="https://beian.miit.gov.cn" style="text-decoration: none;cursor: pointer;color: #000" target="_blank" rel="nofollow" data-v-00487b9c="">
        京ICP备17069710号-2
      </a>
      </div>
  </div>
</template>
<script>
export function randomNum(len, radix) {
  const chars =
    "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz".split("");
  const uuid = [];
  radix = radix || chars.length;

  if (len) {
    // Compact form
    for (let i = 0; i < len; i++) uuid[i] = chars[0 | (Math.random() * radix)];
  } else {
    // rfc4122, version 4 form
    let r;

    // rfc4122 requires these characters
    uuid[8] = uuid[13] = uuid[18] = uuid[23] = "-";
    uuid[14] = "4";

    // Fill in random data.  At i==19 set the high bits of clock sequence as
    // per rfc4122, sec. 4.1.5
    for (let i = 0; i < 36; i++) {
      if (!uuid[i]) {
        r = 0 | (Math.random() * 16);
        uuid[i] = chars[i === 19 ? (r & 0x3) | 0x8 : r];
      }
    }
  }
  return uuid.join("") + new Date().getTime();
}

import { createNamespacedHelpers } from "vuex";
const { mapActions } = createNamespacedHelpers("login");
import ajax from "@/plugins/axios";
import {
  getRememberItem,
  setRememberItem,
  setToken,
  clearRemember,
} from "@/views/common/store";
export default {
  name: "login",
  data() {
    return {
      username: "",
      password: "",
      code: "",
      key: randomNum(24, 16),
      checked: false, // 保存密码
      showForgotPass: false,
      showAnswer: false, //   回答问题找回密码
      showResult: false, //  显示结果
      phoneEnd: "1166", // 尾号
      answerForm: {
        phoneNumber: "", // 电话号码
        housemaster: "", // 宿管名称
        historyPassword: "", // 历史密码
      },
      newPass: "", ///   新密码
      imgSrc: "",
    };
  },
  methods: {
    ...mapActions(["login", "getNewPass", "getPhoneEnd"]),

    getCodeImage() {
      ajax
        .get(`/auth/captcha?key=${this.key}`, {
          responseType: "arraybuffer",
          needAll: true,
          isLogin: true,
        })
        .then((res) => {
          console.log(res);
          return (
            "data:image/png;base64," +
            btoa(
              new Uint8Array(res.data).reduce(
                (data, byte) => data + String.fromCharCode(byte),
                ""
              )
            )
          );
        })
        .then((res) => {
          console.log(res);
          this.imgSrc = res;
        })
        .catch(() => {
          this.$message.error("获取图片失败");
        });
    },
    forgotPass() {
      ///
      this.getPhoneEnd().then((res) => {
        this.phoneEnd = res;
        this.newPass = "";
        this.showForgotPass = true;
      });
    },
    sure() {
      ////
      this.getNewPass(this.answerForm).then(() => {
        this.newPass = Math.random() > 0.5 ? "123456" : "";
        this.showAnswer = false;
        this.showResult = true;
      });
    },
    loginSubmit() {
      const { username, password, key, code } = this;
      this.login({ username, password, key, code })
        .then(() => {
          if (this.checked) {
            setRememberItem(username, password);
          } else {
            clearRemember();
          }
          this.$message.success("登录成功！");
          setToken(username);
          this.$router.push("/school");
        })
        .catch(() => {
          this.code = ""; //   刷新验证码
          this.getCodeImage();
        });
    },
    changKeys() {
      // this.key = (Math.random() * 1000000000) >>> 0;
    },
  },
  watch: {
    showAnswer(val) {
      if (!val) {
        //  弹窗消失  清空数据
        this.answerForm = {
          phoneNumber: "", // 电话号码
          housemaster: "", // 宿管名称
          historyPassword: "", // 历史密码
        };
      }
    },
  },
  computed: {},
  mounted() {
    this.getCodeImage();
    const remember = getRememberItem();
    if (remember) {
      const { username, password } = remember;
      this.username = username;
      this.password = password;
      this.checked = true;
    }
  },
};
</script>
<style lang="scss" scoped>
.login {
  .login-header {
    background: #fff;
    height: 60px;
    width: 1200px;
    padding: 0;
    color: #ff6a00;
    font-weight: bold;
    margin: 0 auto;
    img {
      margin-right: 8px;
    }
  }
  .login-content-box {
    height: 100vh;
    .login-content-title {
      margin-top: 268px;
      font-size: 36px;
      height: 210px;
      color: #230a01;
      font-weight: 500;
      span {
        font-size: 17px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.6);
      }
    }
    .login-content-enter-box {
      width: 360px;
      margin-top: 268px;
      height: 400px;
      background: #fff;
      padding: 36px 32px;
      .login-content-enter-title {
        font-size: 14px;
        color: #230a01;
        span {
          color: #ff6a00;
        }
      }
      .login-content-welcome {
        font-size: 24px;
        font-weight: 600;
        color: #242f57;
        margin: 8px 0 24px 0;
      }
      .el-input {
        margin-bottom: 16px;
        input {
          height: 32px;
        }
      }
      .login-submit-btn {
        margin-top: 24px;
      }
    }
  }

  .company-message {
    margin: 0 auto;
    width: 1200px;
    height: calc(100vh - 752px);
    min-height: 215px;

    font-size: 18px;
    font-weight: 400;
    color: #5a5a5a;
    font-size: 18px;
    padding-top: 59px;
    .c-9 {
      color: #999;
    }
    .c-gray {
      color: #8c8d8d;
    }
  }
  .footer {
    line-height: 60px;
    height: 60px;
    border-top: 1px solid #b6b6b7;
    background: #ebeced;
    text-align: center;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #b6b6b7;
  }
}
.answer-box {
  .el-input + .el-input {
    margin-top: 8px;
  }
  .btn-box {
    justify-content: center;
    button {
      margin: 0 8px;
      width: 88px;
    }
  }
}
.result-box {
  img {
    display: block;
    width: 80px;
    height: 80px;
  }
  .result-title {
    margin-top: 24px;
    font-size: 24px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.85);
    line-height: 32px;
  }
  .result-detail {
    margin-top: 8px;
  }
  .btn-box {
    justify-content: center;
    margin-top: 24px;
    button {
      margin: 0 8px;
      width: 88px;
    }
  }
}
</style>
<style lang="scss">
.forgot-pass .el-dialog__body {
  padding: 0px 24px 24px 24px;
  input {
    margin-bottom: 8px;
    height: 36px;
  }
}
.forgot-title {
  font-size: 13px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.5);
  line-height: 20px;
}
</style>
